import { Link } from "react-router-dom";

const Linking = () => {
    return(
      <div className="catalog-div-main" style={{overflow: 'hidden'}}>
        <div style={{overflow: 'hidden'}}>
        <div className="catalog-div" style={{overflow: 'hidden'}}>
          <Link className="catalogButt"  to='/audiotubes'>🔊 Аудіо трубки</Link>
          <Link className="catalogButt" style={{marginRight: '0px', marginLeft: '0px'}} to='/video-intercom'>🎞️ Відеодомофони</Link>
          <Link className="catalogButt" to='/call-panels'>📷 Викличні панелі</Link>
        </div>
        </div>
      </div>
    )
}

export {Linking}