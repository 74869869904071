import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import '../css/buy.css';

import { DoUWannaDelete } from './DoUWannaDelete';

const ViewImagesForAdmin = ({ images, closeWindow }) => {
    const [ActiveWindow, setActiveWindow] = useState(false)
    const [currentID, setCurrentID] = useState()

    function openWin(e) {
        setCurrentID(e.target.id)
        setActiveWindow(true)
    }

    function closeWin() {
        setActiveWindow(false);
        closeWindow();
    }

    function ifNo() {
      setActiveWindow(false);
  }

  return (
    <>
    <div className="modal">
      <div className="modal-content" style={{height: '500px', width: '800px'}}>
        <Link className='dlt' style={{ marginLeft: '780px', color: 'white' }}
          onClick={closeWindow}
        >✖</Link>
        <div className="image-gallery-2" style={{marginRight: '20px', marginLeft: '20px', marginTop: '20px', marginBottom: '20px'}}>
          {images.map((image, index) => (
            <div>
                <Link className='dlt' style={{ marginRight: '10px', zIndex: '1' }} id={image.id} onClick={openWin}>❌</Link>
                <img
                key={index}
                src={`data:image/jpeg;base64,${image.data}`}
                alt={image.filename}
                style={{ margin: '10px', blockSize: '200px'}}
                />
            </div>
          ))}
        </div>
      </div>
    </div>
    {ActiveWindow &&
        <DoUWannaDelete currentID={currentID} closeWin={closeWin} ifNo={ifNo}/>
      }
    </>
  );
};

export { ViewImagesForAdmin };
