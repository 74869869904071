import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { CurrentIDContext } from '../CurrentIDContext';
import { CheckPsw } from './components/CheckPsw';

const CheckQuestions = () => {
    const [questions, setQuestions] = useState([]);
    const { setCurrentID } = useContext(CurrentIDContext);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://hqua0323033.online-vm.com/check_questions');
            setQuestions(response.data);
        } catch (error) {
            console.error('Error during request: ', error);
            alert('Error during request');
        }
    };

    const [isCorrect, setIsCorrect] = useState(false);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        const storedIsCorrect = localStorage.getItem('isCorrect');
        if (storedIsCorrect === 'true') {
        setIsCorrect(true);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('isCorrect', isCorrect.toString());
    }, [isCorrect]);

    return (
        <div style={{display: 'flex', justifyContent: 'center', fontFamily: '"Montserrat", sans-serif', marginTop: '200px'}}>
            <title>Список запитів</title>
            {isCorrect ? 
            <div style={{marginTop: '-70px'}}>
            <div className='swipe-pages' style={{position: 'absolute', right: '10px', marginTop: '10px'}}>
                <Link style={{color: 'white'}} to='/history-questions'>Історія запитів</Link>
            </div>
            <div style={{ marginBottom: '200px', display: 'flex', justifyContent: 'center' }}>
                <div>
                    <h2 style={{ textAlign: 'center' }}>Список актуальних запитів до тех. підтримки:</h2>
                    {questions.filter((el) => el.isCompleted !== true).sort((a, b) => new Date(b.Date) - new Date(a.Date)).map((el) => (
                        <div className='orders' key={el.id} style={{ position: 'relative' }}>
                            <h4>Ім'я: {el.Name} {el.Surname} {el.Middlename}</h4>
                            <h4>Контактні дані: {el.EmailPhone}</h4>
                            <h4>Тема: {el.Theme}</h4>
                            <h4 style={{ marginBottom: '60px' }}>Дата: {el.Date}</h4>
                            <div style={{ position: 'absolute', right: '10px', marginTop: '-50px' }}>
                                <Link className="button-27" onClick={() => setCurrentID(el.id)} to='check-all/'>Переглянути</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            </div>
            : 
                <div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <h3 style={{position: 'absolute', top: '150px', color: 'red'}}>{msg}</h3>
                </div>
                <CheckPsw setIsCorrect={setIsCorrect} setMsg={setMsg}/>
                </div> 
            }
        </div>
    );
};

export {CheckQuestions};
