import React from 'react';
import { Link } from 'react-router-dom';
import '../css/buy.css';

const ViewDescription = ({ desc, closeWindow }) => {


  return (
    <div>
        <div className="modal">
            <div className="modal-content" style={{width: '500px'}}>
                <Link
                  className='dlt'
                  style={{ position: 'absolute', top: '20px', right: '20px', color: 'white' }}
                  onClick={closeWindow}
                >✖</Link>
                <h4 style={{textAlign: 'left', marginTop: '40px', wordWrap: 'break-word', 
                      overflowWrap: 'break-word',
                      overflow: 'hidden'}}
                >{desc}</h4>
            </div>
        </div>
    </div>
  );
};

export { ViewDescription };
