import for_about_main from '../images/for_about_main.png'


const AboutUs = () => {
    return(
        <div className='about-us-width'>
            <title>Про Нас</title>
            <div>
                <div style={{display: 'flex'}}>
                <img src={for_about_main} style={{blockSize: '50px'}} alt='about-img' />
                <p style={{color: 'rgb(226, 101, 33)', marginTop: '33px', marginLeft: '-37px', fontSize: '12px'}}>®</p>
                </div>
                <h4><span className='for-span'>Безкоштовно*</span> надаємо:</h4>
                <div style={{marginLeft: '15px', marginTop: '-20px'}}>
                    <li>
                        естетичний блок виклику (панель домофона) з
                        великими зручними кнопками з підсвіткою та блок
                        керування домофоном та високоякісною кольоровою
                        відеокамерою (при встановленні відеомоніторів);
                    </li>
                    <li style={{marginTop: '10px'}}>
                    стандартне** встановлення переговорних пристроїв
                    та індивідуальних блоків виклику (в місці виходу
                    кабеля зі стіни без додаткового обладнання).    
                    </li>
                    <li style={{marginTop: '10px'}}>
                    1 електронний ключ на кожну квартиру і 5 ключів
                    для техперсоналу будинку.
                    </li>       
                </div>
                <h4>Вартість</h4>
                <div style={{marginLeft: '15px', marginTop: '-20px'}}>
                    <li>
                    виготовлення та заміна вхідних дверей <span className='for-span'>вираховується індивідуально</span>;
                    </li>
                    <li>
                    підключення квартири — <span className='for-span'>від 590грн</span>;
                    </li>
                    <li>
                    аудіопереговорні пристрої (трубки) — <span className='for-span'>від 250грн</span>;
                    </li>
                    <li>
                    відеопереговорні пристрої (монітори) — <span className='for-span'>від 2 990грн</span>;
                    </li>
                    <li>
                    виклик майстра для 4-х і більше квартир одночасно — <span className='for-span'>безкоштовно</span>;
                    </li>
                    <li>
                    індивідуальний виклик майстра після завершення робіт — <span className='for-span'>300грн</span>;
                    </li>
                    <li>
                    додатковий електронний ключ при встановленні — <span className='for-span'>70грн</span>;
                    </li>
                    <li>
                    додатковий електронний ключ після закінчення робіт — <span className='for-span'>100грн</span>;
                    </li>
                    <li>
                    технічне обслуговування — <span className='for-span'>від 30.90грн/місяць</span>.
                    </li>
                </div>
                <h4>Додаткове обладнання</h4>
                <p style={{marginTop: '-15px'}}>
                У нас найвигідніші умови на придбання переговорних пристроїв та
                індивідуальних блоків виклику, оскільки за ціною офіційного прайс-листа
                виробника Ви отримуєте розширену гарантію та безкоштовне стандартне
                встановлення. Вартість додаткових робіт і матеріалів погоджується по місцю. <br/><br/>
                При встановленні моніторів з виходом в інтернет*** Ви отримаєте можливість
                приймати аудіо-відеовиклики на смартфон та дистанційного відчиняти двері.
                </p>
                <h4>Обслуговуючись у ДОМОФОН СЕРВІС Ви отримуєте такі переваги:</h4>
                <div style={{marginLeft: '15px', marginTop: '-15px'}}>
                    <li>
                    <span style={{fontWeight: 'bold'}}>найповніший перелік безкоштовних послуг</span>, які вже включені у вартість
                    обслуговування і за які непотрібно доплачувати (щоквартальний огляд та
                    профілактичне техобслуговування усієї системи, продовження гарантії на
                    придбане у нас обладнання, безлімітний виклик майстра, заміна дотягувача
                    дверей та зламаних ключів, ремонт та заміна пошкодженого обладнання,
                    ремонт та реставрація дверей, усунення наслідків влучання блискавок,
                    пожеж, вандальних дій тощо, майбутні модернізації та багато іншого);
                    </li>
                    <li>
                    <span style={{fontWeight: 'bold'}}>чесне та прозоре ціноутворення</span> на додаткове обладнання (за роздрібною
                    ціною виробника, з безкоштовним стандартним монтажем);
                    </li>
                    <li>
                    <span style={{fontWeight: 'bold'}}>співпраця з лідером ринку</span> — обслуговування у надійній організації, яка
                    першою у 2007р. з’явилась на ринку, а сьогодні забезпечує працездатність
                    домофонів більше половини багатоквартирних будинків Львова;
                    </li>
                    <li>
                    <span style={{fontWeight: 'bold'}}>великий склад запчастин та підмінного фонду</span> (якщо виникає
                    необхідність замінити несправний модуль, ми не замовляємо його у
                    виробника — наш майстер вже має його при собі!);
                    </li>
                    <li>
                    <span style={{fontWeight: 'bold'}}>власний сервісний центр</span>, авторизований більшістю провідних виробників
                    (у той час, як інші організації відправляють несправне обладнання на
                    ремонт до сервісних центрів у Києві або до нас, ми виконуємо ремонт
                    самостійно і швидко у Львові, а Ви — заощаджуєте дорогоцінний час,
                    оскільки обладнання наших клієнтів ми ремонтуємо поза чергою).
                    </li>
                </div>
                <p>Виклик майстра або придбання ключів: <span style={{fontWeight: 'bold'}}>(032 / 098) 244-44-34</span>.</p>
                <p style={{marginTop: '-15px'}}>Консультації та замовлення нового підключення: <span style={{fontWeight: 'bold'}}>(067) 455-94-34, (067) 455-94-39</span>.</p>
                <p style={{textDecoration: 'underline', fontStyle: 'oblique'}}>Всі ціни вказані станом на 01.03.2024р.</p>
            </div>
        </div>
    )}

export {AboutUs}