import '../css/buy.css';

const Delete = (props) => {
    const deleteEl = async(e) => {
        e.preventDefault();

        const formData = {
            id: props.productID,
            dbClass: props.productDBclass
        };

        try {
            await fetch('https://hqua0323033.online-vm.com/delete_product', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            props.closeWindow();
        } catch (error) {
            console.error('Error during deletion: ', error);
            alert('Error during deletion');
        }
    };

    return (
        <div>
            <div className="modal">
                <div className="modal-content">
                    <h2 style={{textAlign: 'center'}}>Ви дійсно хочете видалити цей товар?</h2>
                    <button className='floating-button' onClick={props.closeWindow}>Ні</button>
                    <button type='submit' className='floating-button' style={{marginLeft: '200px'}} onClick={deleteEl}>Так</button>
                </div>
            </div>
        </div>
    );
};

export { Delete };
