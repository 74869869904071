import React from 'react';
import { Routes, Route, Link } from 'react-router-dom'

import { CurrentIDProvider } from './CurrentIDContext';

import { HomePage } from './pages/HomePage';
import { ErrorPage } from './pages/ErrorPage'
import { VideoIntercom } from './pages/VideoIntercom';
import { IpIntercom } from './pages/IpIntercom';
import { AboutUs } from './pages/AboutUs';
import { Support } from './pages/Support';
import { Catalog } from './pages/Catalog'
import { Success } from './pages/Success';
import { AudioTubes } from './pages/AudioTubes';
import { CallPanels } from './pages/CallPanels';
import { Dahua } from './pages/Dahua';
import { Accessories } from './pages/Accessories';
import { CheckOrders } from './pages/CheckOrders';
import { HistoryOrders } from './pages/HistoryOrders';
import { TestWatchImg } from './pages/TestWatchImg';
import { Upload } from './pages/Upload';
import { UploadMore } from './pages/UploadMore';
import { SuccessSupport } from './pages/SuccessSupport';
import { CheckQuestions } from './pages/CkeckQuestions';
import { CheckAll } from './pages/CheckAll'
import { HistoryQuestions } from './pages/HistoryQuestions';
import { AddTelegramID } from './pages/AddTelegramID';


import phone from './images/phone.png'
import threelines from './images/threelines.png'

function App() {
  return (
    <>
      <div>
        <header className='static-block'>
          <div style={{backgroundColor: 'rgb(10, 10, 10)', height: '75px'}}>
            <div style={{marginTop: '75px'}}>
            <div style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
              <div className='display-div'>
                <Link className="butt" style={{marginLeft: '-40px'}} to="/">Домосервіс</Link>
                <Link className="butt" to="/about-us">Про Нас</Link>
                <Link className="butt" to="/support">Тех. Підтримка</Link>
                <div className='header-phone' style={{height: '100px', marginLeft: '-25px'}}>
                  <img src={phone} alt='phone' style={{width: '20px', height: '20px', position: 'absolute', marginTop: '10px', marginLeft: '-50px'}}/>
                  <div style={{ width: '200px', marginTop: '-15px'}}>
                    <p className="home">098-244-44-34<br></br><span className="home" style={{ fontSize: '16.3px', top: '-50px'}}>032-244-44-34</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className='onOff'>
              <div className='div-phone'>
                  <Link className="butt2" style={{marginRight: '50px'}} to="/">Домосервіс</Link>
                  <div className='header-phone' style={{height: '100px', float: 'right'}}>
                  <img src={phone} alt='phone' style={{width: '20px', height: '20px', position: 'absolute', marginTop: '10px', marginLeft: '-25px'}}/>
                  <div style={{ width: '120px', marginTop: '-15px'}}>
                    <p className="home">098-244-44-34<br></br><span className="home" style={{ fontSize: '16.3px', top: '-50px'}}>032-244-44-34</span></p>
                  </div>
              </div>
              </div>
              <div className="menuposs3">
                <ul className="menu">
                  <li style={{width: '20px'}}>
                    <img src={threelines} alt='threelines' style={{height: '20px', width: '20px'}} className="listtop"/>
                    <ul className="list">
                      <li><Link to="/about-us">Про Нас</Link></li>
                      <li><Link to="/support">Тех. Підтримка</Link></li>
                      <li className='onOffPhoneMenu'>
                        <Link className="home">098-244-44-34</Link>
                      </li>
                      <li className='onOffPhoneMenu'>
                        <Link className="home">032-244-44-34</Link>
                      </li>
                    </ul>
                  </li>
                  </ul>
                </div>
                </div>
            </div>
          </div>
        </header>
      </div>
    <CurrentIDProvider>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/support" element={<Support />} />
        <Route path="/video-intercom" element={<VideoIntercom />} />
        <Route path="/ip-intercom" element={<IpIntercom />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/success" element={<Success />} />
        <Route path="/success-support" element={<SuccessSupport />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/audiotubes" element={<AudioTubes />} />
        <Route path="/call-panels" element={<CallPanels />} />
        <Route path="/dahua" element={<Dahua />} />
        <Route path="/accessories" element={<Accessories />} />
        <Route path="/check-orders" element={<CheckOrders />} />
        <Route path="/history-orders" element={<HistoryOrders />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/test-watch-img" element={<TestWatchImg />} />
        <Route path="/upload-more" element={<UploadMore />} />
        <Route path="/check-questions" element={<CheckQuestions />} />
        <Route path="/history-questions" element={<HistoryQuestions />} />
        <Route path="/check-questions/check-all" element={<CheckAll />} />
        <Route path="/history-questions/check-all" element={<CheckAll />} />
        <Route path="/add-telegram-id" element={<AddTelegramID />} />
      </Routes>
    </CurrentIDProvider>
    <footer style={{textAlign: 'center', opacity: '0.5'}}>
      <p style={{marginTop: '100px', fontSize: '20px'}}>Тов Домосервіс</p>
      <p>Адреса:</p>
      <p style={{marginTop: '-15px'}}>м. Львів</p>
      <p style={{marginTop: '-15px'}}>вул. Зелена 115А</p>
      <p style={{marginTop: '-10px', marginBottom: '100px'}}>© 2008-2024</p>
    </footer>
    </>
  );
}

export default App;