import React from 'react';
import { Link } from 'react-router-dom';
import '../css/buy.css';

const DoUWannaDelete = ({ currentID, closeWin, ifNo }) => {

    const deleteSelectImg = async(e) => {
        e.preventDefault();

        const formData = {
            'id': currentID
        }

        try {
            await fetch('https://hqua0323033.online-vm.com/delete_select_img', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            closeWin();
        } catch (error) {
            console.error('Error during deletion: ', error);
            alert('Error during deletion');
        }
    }


  return (
    <div>
        <div className="modal">
            <div className="modal-content">
                <h2 style={{textAlign: 'center'}}>Ви дійсно хочете видалити це зображення?</h2>
                <button className='floating-button' onClick={ifNo}>Ні</button>
                <button type='submit' className='floating-button' style={{marginLeft: '200px'}} onClick={deleteSelectImg}>Так</button>
            </div>
        </div>
    </div>
  );
};

export { DoUWannaDelete };
