import { Link } from "react-router-dom";

import { Linking } from "./components/Linking";

import one from './images/one.png';
import two from './images/two.png';
import three from './images/three.png'

const Catalog = () => {
    return (
        <div>
            <title>Каталог</title>
            <div className="catalog-div">
                <Linking/>
            </div>
            <div className="content-container" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px'}}>
                <p className="ctlgP" style={{}}>
                    <h3>Аудіодомофон: <br></br></h3>
                    <strong>Звуковий зв'язок:</strong> Основна функція аудіодомофона - передача голосового зв'язку між абонентами. Він дозволяє спілкуватися за допомогою звуку,
                    але не передає відеоінформацію. <br></br>
                    <strong>Простота:</strong> Зазвичай менш складний і більш доступний з погляду вартості, оскільки не включає в себе складних відеокамер та екранів. <br></br>
                    <strong>Базовий рівень безпеки:</strong> Забезпечує основний рівень безпеки та зручності, але не надає візуальну інформацію. <br></br>
                </p>
                <img className='ctlgImg' src={one} alt="one" style={{ marginLeft: '50px', marginRight: '50px'}}/>
            </div>
            <div className="content-container" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px'}}>
                <p className="ctlgP" style={{ maxWidth: '1511px'}}>
                    <h3>Відеодомофон: <br></br></h3>
                    <strong>Відео та звуковий зв'язок:</strong> Передає інформацію як звук, так і відео. Дозволяє переглядати відвідувачів на екрані або моніторі. <br></br>
                    <strong>Візуальна ідентифікація:</strong> Надає можливість візуально ідентифікувати осіб, які перебувають перед вхідними дверима чи брамою. <br></br>
                    <strong>Додаткові функції:</strong> Може включати режими відеоспостереження, можливість зберігати відеозаписи, функції контролю доступу,
                    можливість підєднання до смартфону для та переадресації виклику віддаленого перегляду зображення перед дверима як онлайн та і в записі. <br></br>
                    <strong>Вищий рівень безпеки:</strong> Забезпечує вищий рівень безпеки за рахунок візуального спостереження. <br></br>
                </p>
                <img className='ctlgImg' src={two} alt="two" style={{ marginLeft: '50px', marginRight: '50px'}}/>
            </div>
            <div className="content-container" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px'}}>
                <p className="ctlgP" style={{maxWidth: '1511px'}}>
                    <h3>Виклична відеопанель для відеодомофону: <br></br></h3>
                    Це пристрій, розташований біля вхідних дверей квартири, який підключається до додаткового каналу відеодомофону. 
                    Цей пристрій має вбудовану камеру, мікрофон і динамік, а також кнопку виклику.З нього можна вести запис по руху всього що відбувається в коридорі 
                    (за умови підтримки функції DVR відеодомофоном)
                </p>
                <img className='ctlgImg' src={three} alt="three" style={{ marginLeft: '50px', marginRight: '50px'}}/>
            </div>
        </div>
    );
}

export { Catalog };
