import { Link } from 'react-router-dom'

const Success = () => {
    return(
        <div style={{marginTop: "200px", marginBottom: '200px'}}>
            <title>Sucсess</title>
            <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', justifyItems: 'center'}}>
                <h1>Ваше замовлення успішно прийнято! <br></br>
                Очікуйте на відповідь за Вашими контактними даними.<br></br></h1>
            </div>
            <div style={{textAlign: 'center'}}>
                <Link to="/" style={{fontSize: '30px', fontWeight: 'bold'}}>На головну</Link>
            </div>
        </div>
    )
}

export {Success}