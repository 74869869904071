import { Link, useNavigate } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react';
import { CurrentIDContext } from '../CurrentIDContext';
import axios from 'axios';

const CheckAll = () => {
    const navigate = useNavigate();

    const [questions, setQuestions] = useState([])
    const { currentID } = useContext(CurrentIDContext);

    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        try {
          const response = await axios.get('https://hqua0323033.online-vm.com/check_questions');
          setQuestions(response.data);
        } catch (error) {
          console.error('Error during request: ', error);
          alert('Error during request');
        }
      }

    const Completed = async(isCompleting) => {

      const formData = {
          isCompleted: isCompleting,
          currentID: currentID,
          option: 'Questions'
      }
  
      await fetch('https://hqua0323033.online-vm.com/completed', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
      });

      fetchData();
    }

    const CompletedTrue = () => {
      var isCompleting = true
      Completed(isCompleting)
    }
    const CompletedFalse = () => {
      var isCompleting = false
      Completed(isCompleting)
    }

    const Delete = async() => {

      const formData = {
          currentID: currentID,
          option: 'Questions'
      }
  
      await fetch('https://hqua0323033.online-vm.com/delete_object', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
      });

      navigate('/check-questions')
    }

    return(
      <>
      {currentID ? 
        <div>
          <Link className='swipe-pages' style={{marginTop: '-50px', marginLeft: '10px', color: 'white', position: 'absolute'}} to='/check-questions'>Назад</Link>
            <div style={{ marginBottom: '200px', marginLeft: '200px', marginRight: '200px'}}>
                <div>
                    {questions.filter(el => currentID === el.id).map((el) => (
                    <div key={el.id}>
                      <title>{el.Theme}</title>
                      <h2 style={{textAlign: 'center', marginBottom: '100px', marginTop: '200px'}}>Тема: {el.Theme}</h2>
                        <h4>Ім'я: {el.Name} {el.Surname} {el.Middlename}</h4>
                        <h4>Контактні дані: {el.EmailPhone}</h4>
                        <h4><span style={{textDecoration: 'underline'}}>Питання</span>: {el.Question}</h4>
                        <h4 style={{textAlign: 'right', marginTop: '50px', opacity: '0.5'}}>Дата: {el.Date}</h4>
                        <div style={{position: 'absolute', right: '10px', marginTop: '-50px'}}>
                        </div>
                        <div style={{float: 'right', marginTop: '50px'}}>
                          {(el.isCompleted === false) && <Link className="button-27" style={{width: '300px'}} onClick={CompletedTrue}>Позначити як "Виконано"</Link>}
                          {(el.isCompleted === true) && <Link className="button-27" style={{width: '300px'}} onClick={CompletedFalse}>Позначити як "Не виконано"</Link>}
                        </div>
                        <div style={{marginTop: '70px'}}>
                          <Link className="button-27" style={{width: '300px', color: 'red'}} onClick={Delete}>Видалити</Link>
                        </div>
                    </div>
                    ))}
                </div>
            </div>            
        </div>
        : <div style={{textAlign: 'center', marginTop: '200px'}}>
          <p style={{fontSize: '40px'}}>СТАЛОСЯ ЩОСЬ ДИВНЕ!</p>
          <Link style={{fontSize: '30px'}} to="/check-questions">Повернутись назад</Link>
          </div>}
        </>
    )
}

export {CheckAll}