import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './css/select.css';
import { CheckPsw } from './components/CheckPsw';

const Upload = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    const storedIsCorrect = localStorage.getItem('isCorrect');
    if (storedIsCorrect === 'true') {
      setIsCorrect(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('isCorrect', isCorrect.toString());
  }, [isCorrect]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const option = document.getElementById('selectedZone').value;

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('file', file);
    });
    formData.append('product', selectedProduct);
    formData.append('price', selectedPrice);
    formData.append('description', selectedDescription);
    formData.append('option', option);

    if (option === '0') {
      alert('Будь ласка, оберіть корректну категорію');
    } else {
      try {
        await axios.post('https://hqua0323033.online-vm.com/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('Дані успішно завантажені!');
        setSelectedFiles([]);
        setSelectedDescription('');
        setSelectedProduct('');
        setSelectedPrice('');
      } catch (error) {
        console.error('Error uploading files: ', error);
        alert('Error uploading files');
      }
    }
  };

  return (
    <div style={{display: 'flex', justifyContent: 'center', fontFamily: '"Montserrat", sans-serif', marginTop: '200px'}}>
      <title>Додавання товару</title>
      {isCorrect ? 
        <form onSubmit={handleSubmit} style={{backgroundColor: 'rgb(100, 100, 100)', borderRadius: '20px'}}>
          <div style={{marginLeft: '10px', marginRight: '10px'}}>
            <div className='custom-select' style={{ marginTop: '20px', opacity: '0.9'}}>
              <select id='selectedZone' required>
                <option value='0'>Оберіть категорію:</option>
                <option value='AudioTubes'>Аудіотрубки</option>
                <option value='VideoIntercom'>Відеодомофони</option>
                <option value='CallPanels'>Викличні панелі</option>
                <option value='IpIntercom'>IP-Домофони</option>
                <option value='Dahua'>Dahua</option>
                <option value='Accessories'>Додаткові аксесуари</option>
              </select>
            </div>
            <input type="file" onChange={handleFileChange} required style={{marginTop: '20px', fontFamily: '"Montserrat", sans-serif'}}/>
            {selectedFiles.map((file, index) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt="some"
                style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }}
              />
            ))}
            <div className="group" style={{marginTop: '30px'}}>
              <input 
                type="text" 
                required 
                value={selectedProduct}
                style={{background: 'none', color: 'white'}} 
                onChange={(e) => setSelectedProduct(e.target.value)}
              />
              <span className="bar"></span>
              <label>*Назва товару</label>
            </div>
            <div className="group" style={{marginTop: '30px'}}>
              <input 
                type="text" 
                required
                value={selectedPrice}
                style={{background: 'none', color: 'white'}} 
                onChange={(e) => setSelectedPrice(e.target.value)}
              />
              <span className="bar"></span>
              <label>*Ціна (без "грн")</label>
            </div>
            <div className="group" style={{marginTop: '30px'}}>
              <input 
                type="text"  
                required
                value={selectedDescription}
                style={{background: 'none', color: 'white'}} 
                onChange={(e) => setSelectedDescription(e.target.value)}
              />
              <span className="bar"></span>
              <label>*Опис</label>
            </div>
            <button className='floating-button' type="submit" style={{width: '300px', marginLeft: '220px'}}>Додати товар до каталогу</button>
          </div>
        </form>
      : 
        <div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <h3 style={{position: 'absolute', top: '150px', color: 'red'}}>{msg}</h3>
          </div>
          <CheckPsw setIsCorrect={setIsCorrect} setMsg={setMsg}/>
        </div> 
      }
    </div>
  );
};

export { Upload };