import React, { useState } from "react";
import axios from "axios";

const CheckPsw = (props) => {
  const [password, setPassword] = useState('');

  const admin = async (e) => {
    e.preventDefault();

    const postData = { 'password': password };

    try {
      const response = await axios.post('https://hqua0323033.online-vm.com/admin', postData, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      props.setIsCorrect(response.data.is_correct);
      if (response.data.is_correct) {
        localStorage.setItem('isCorrect', 'true');
      }
      props.setMsg(response.data.msg);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  return (
    <div>
      <form onSubmit={admin} style={{ backgroundColor: 'rgb(100, 100, 100)', borderRadius: '20px', height: '240px' }}>
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
          <div>
            <p style={{ position: 'relative', top: '50px' }}>Введіть пароль, щоб отримати доступ до панелі адміністратора</p>
          </div>
          <div className="group">
            <input
              type="password" // Изменено тип на password для безопасности
              required
              value={password}
              style={{ background: 'none', color: 'white', marginTop: '70px' }}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="bar"></span>
            <label>*Пароль</label>
          </div>
          <button className='floating-button' type="submit" style={{ marginLeft: '380px' }}>Далі</button>
        </div>
      </form>
    </div>
  );
};

export { CheckPsw };