import React, { createContext, useState } from 'react';

// Create the context
export const CurrentIDContext = createContext();

// Create a provider component
export const CurrentIDProvider = ({ children }) => {
    const [currentID, setCurrentID] = useState(null);

    return (
        <CurrentIDContext.Provider value={{ currentID, setCurrentID }}>
            {children}
        </CurrentIDContext.Provider>
    );
};