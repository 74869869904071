import { Link } from 'react-router-dom'

import mountains from './images/mountains.jpg'

const HomePage = () => {
    return(
        <div style={{marginTop: "-30px"}}>
            <title>Головна</title>
            <div style={{marginTop: '65px'}}></div>
            <div style={{overflow: 'hidden'}}>
                <div style={{position: 'relative'}}>
                <div className='home-title' style={{position: 'absolute', marginTop: '100px', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1', textAlign: 'center'}}>
                    <h2 className='title-1'>ЛАСКАВО ПРОСИМО ДО ОНЛАЙН КАТАЛОГУ</h2>
                    <h4 className='title-2'>Ціни на обладнання вже включають вартість монтажу*</h4>
                </div>
                    <div>
                        
                            <img src={mountains} style={{blockSize: '1100px', filter: 'blur(5px)', marginLeft: '-10px', height: '100vh', backgroundSize: 'cover', width: '400vh', opacity: '0.7', position: 'relative'}} alt='mountains'/>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div>
                            <Link className="homeButt homeButtTop1" style={{position: 'absolute', marginTop: '200px'}} to="/catalog">Каталог обладнання під багатоквартирну <br></br> систему "Vizit", "Cyfral" та "Мрія"</Link>
                            <Link className="homeButt homeButtTop2" style={{position: 'absolute', marginTop: '300px'}} to='/ip-intercom'>Каталог обладнання під багатоквартирну <br></br> IP систему Bas-IP</Link>
                            <Link className="homeButt homeButtTop3" style={{position: 'absolute', marginTop: '400px'}} to='/dahua'>Каталог обладнання під багатоквартирну <br></br> IP систему Dahua</Link>
                            <Link className='accessories homeButtTop4' style={{position: 'absolute', marginTop: '530px', transform: 'translate(-50%, -50%)', textAlign: 'center', width: '450px'}} to='/accessories'>Каталог додаткових аксесуарів</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {HomePage}