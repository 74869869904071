import { Link } from 'react-router-dom'

const ErrorPage = () => {
    return(
        <div style={{marginTop: "100px"}}>
            <title>Помилка</title>
            <h1 style={{textAlign: "center"}}>Page isn't found! <Link to="/" >Home</Link></h1>
        </div>
    )
}

export {ErrorPage}