import '../css/buy.css';
import { Link } from 'react-router-dom';

const MainModal = (props) => {

    return (
        <div>
            <div className="modal">
                <div className="modal-content" style={{width: '500px', height: '500px'}}>
                    <Link className='dlt' style={{ marginLeft: '480px', color: 'white' }}
                    onClick={props.closeWindow}
                    >✖</Link>
                    <h2 style={{textAlign: 'center'}}>Керування товаром</h2>
                    <h3 style={{textAlign: 'center'}}>{props.productName}</h3>
                    <div>
                        <div style={{justifyContent: 'center', display: 'flex', width: '500px', marginTop: '50px'}}>
                            <div style={{position: 'absolute'}}>
                                <Link className='catalog-link' style={{ width: '270px', transform: 'scale(0.8)' }} onClick={props.openChangeName}>Змінити назву</Link>
                            </div>
                            <div style={{position: 'absolute', marginTop: '70px'}}>
                                <Link className='catalog-link' style={{ width: '270px', transform: 'scale(0.8)' }} onClick={props.openChangePrice}>Змінити ціну</Link>
                            </div>
                            <div style={{position: 'absolute', marginTop: '140px'}}>
                                <Link className='catalog-link' style={{ width: '270px', transform: 'scale(0.8)' }} onClick={props.openChangeImg}>Змінити головне зображення</Link>
                            </div>
                            <div style={{position: 'absolute', marginTop: '210px'}}>
                                <Link className='catalog-link' style={{ width: '270px', transform: 'scale(0.8)' }} onClick={props.checkImg}>Додати додаткове зображення</Link>
                            </div>
                            <div style={{position: 'absolute', marginTop: '280px'}}>
                                <Link className='catalog-link' style={{ width: '270px', transform: 'scale(0.8)' }} onClick={props.openChangeDesc}>Змінити опис</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { MainModal };
