import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { CheckPsw } from "./components/CheckPsw";

const AddTelegramID = () => {
    const [chatID, setChatID] = useState('');
    const [success, setSuccess] = useState();

    const DoNext = async (e) => {
        e.preventDefault();

        const formData = {
            ChatID: chatID
        };

        const response = await fetch('https://hqua0323033.online-vm.com/add_chat_id', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        const responseData = await response.json();
        console.log(responseData);

        setSuccess(
            responseData.data
                ? { type: 'success', message: responseData.data }
                : { type: 'error', message: responseData.error }
        );
    }

    const [isCorrect, setIsCorrect] = useState(false);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        const storedIsCorrect = localStorage.getItem('isCorrect');
        if (storedIsCorrect === 'true') {
        setIsCorrect(true);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('isCorrect', isCorrect.toString());
    }, [isCorrect]);

    return (
        <div style={{display: 'flex', justifyContent: 'center', fontFamily: '"Montserrat", sans-serif', marginTop: '200px', textAlign: 'center'}}>
            <title>Chat ID</title>
            {isCorrect ?
            <div style={{marginTop: '-200px'}}>
            <h2 style={{ marginTop: '150px' }}>Додайте Telegram Chat ID для отримання сповіщень з цього сайту</h2>
            <h2>Інструкція:</h2>
            <h4>1. Увійдіть у свій акаунт в Телеграм</h4>
            <h4>2. Відкрийте <span><Link style={{ color: 'rgb(0, 179, 255)' }} to='https://t.me/DomoserviceBot' target="_blank">@DomoserviceBot</Link></span> та запустіть його</h4>
            <h4>3. Перейдіть до <span><Link style={{ color: 'rgb(0, 179, 255)'}} to='https://t.me/getmyid_bot' target="_blank">Get My ID</Link></span> боту, щоб отримати власний Chat ID</h4>
            <h4>4. Запустіть бота, скопіюйте ID та вставте його в форму:</h4>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', marginBottom: '100px' }}>
                <form onSubmit={DoNext}>
                    <div className="group">
                        <input
                            type="text"
                            required
                            style={{ backgroundColor: 'rgb(15, 15, 15)', color: 'white' }}
                            onChange={(e) => setChatID(e.target.value)}
                        />
                        <span className="bar"></span>
                        <label className='labelFont'>Ваш Chat ID</label>
                    </div>
                    <button
                        type='submit'
                        className='floating-button'
                        style={{ marginLeft: '350px' }}>
                        Додати
                    </button>
                </form>
            </div>
            {success && (
                <h3 style={{ color: success.type === 'success' ? 'rgba(111, 255, 0, 0.79)' : 'red' }}>
                    {success.message}
                </h3>
            )}
            </div>
            : 
            <div>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <h3 style={{position: 'absolute', top: '150px', color: 'red'}}>{msg}</h3>
              </div>
              <CheckPsw setIsCorrect={setIsCorrect} setMsg={setMsg}/>
            </div> 
            }
        </div>
    );
}

export { AddTelegramID };
