import { useState } from "react";
import axios from "axios";

const AddImages = (props) => {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
    };

    const toAdd = async(e) => {
        e.preventDefault();

        const formData = new FormData();
        selectedFiles.forEach((file) => {
        formData.append('file', file);
        });
        formData.append('idClass', props.productID);
        formData.append('dbClass', props.productDBclass);

        try {
            await axios.post('https://hqua0323033.online-vm.com/add_images', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            });
            
            props.closeWindow()
    
        } catch (error) {
            console.error('Error uploading files: ', error);
            alert('Error uploading files');
        }
        }


    return(
        <div>
            <div className="modal">
                <div className="modal-content">
                <h2 style={{textAlign: 'center'}}>Виберіть зображення:</h2>
                <input type="file" onChange={handleFileChange} required style={{marginTop: '20px', fontFamily: '"Montserrat", sans-serif'}}/>
                {selectedFiles.map((file, index) => (
                <img
                    key={index}
                    src={URL.createObjectURL(file)}
                    alt="some"
                    style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }}
                />
                ))}
                <div></div>
                    <button className='floating-button' onClick={props.closeWindow}>Ні</button>
                    <button type='submit' className='floating-button' style={{marginLeft: '200px'}} onClick={toAdd}>Додати</button>
                </div>
            </div>
        </div>
    )
}

export {AddImages}